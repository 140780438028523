<template>
  <div class="account">    
    <img :src="accountImg.content" alt="">    
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  computed: {
    ...Vuex.mapGetters([
      "accountImg",
    ]),
  },

}
</script>
<style lang="stylus" scoped>
.account {
  text-align: center;
  & > img{
    width: 24%;  
    max-width: 2.3rem;
    margin: 1.4rem auto 1.2rem;
  }
}
@media screen and (max-width: 980px){
  .account {
    & > img{
      width: 50%;  
      max-width: 50%;
    }
  }
}

</style>